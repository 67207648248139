import http from "../http";
import axios from "axios";

/**
 * 分页查询采购计划管理查询
 */
export const purchaseOrderList = (currentPage, pageSize, data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_INVENTORY_URL + `reserve/v1/reserves/search?currentPage=${currentPage}&pageSize=${pageSize}`,
    data
  });
};

/**
 * 新增采购计划管理信息获取uuid
 */
export const purchasegetuuid = () => {
  return http({
    method: "get",
    url: process.env.VUE_APP_INFRASTRUCTURE_URL +`/infrastructure/v1/getUUID`,
  });
};

/**
 * 新增采购计划拉取客订数据管理信息
 */
// export const purchasegetdata = (data) => {
//   return http({
//     method: "post",
//     url: process.env.VUE_APP_OMS_URL+ `saleorder/oms/saleorders/saleorderanditem`,
//     data
//   });
// };
const CommonUnit = {individual :'个',Box:'箱',Kg:'公斤',onePieces:'件',Cover:'套',Piece:'条',Book:'本',Meter:'米',Package:'包'}
export const getSoItem = (params) => {
  let data = {
    orderBy: [],
    query: [
      { fieldName: 'soNo', opr: 'eq', value: params.saleOrderId },
      { fieldName: 'objectNo', opr: 'eq', value: params.saleOrderId },
      { fieldName: 'invoiceNo', opr: 'eq', value: params.saleOrderId },
    ],
    subTotal: [],
    groupBy: []
  };
  let result = http({
    method: 'post',
    url: `${process.env.VUE_APP_OMS_URL}soItem/v2/index?currentPage=1&pageSize=9999`,
    data
  });
  result._then = result.then
  result.then = (cb) => {
    result._then((res) => {
      let r = res.data.records
      r = r.map(item => {
        item.productCode = item.productNo // 产品编号
        item.materialCode = item.materialNo // 物料编号
        item.purchaseMeasurementUnit = CommonUnit[item.unit] // 单位字段
        return item;
      });
      cb(r);
    });
  }
  return result;
}
export const getSo = (params) => {
  let data = { orderBy: [], query: [{ fieldName: 'invoiceNo', opr: 'eq', value: params.saleOrderId }], subTotal: [], groupBy: [] }
  let result = http({
    method: 'post',
    url: `${process.env.VUE_APP_OMS_URL}so/v2/index?currentPage=1&pageSize=20`,
    data
  });
  result._then = result.then;
  result.then = (cb) => {
    result._then((res) => {
      let r = res.data.records;
      r = r.map(item => {
        item.customerName = item.businessCustomerName;
        item.customerId = item.businessCustomerNo;
        return item;
      })[0];
      cb(res);
    });
  }
  return result;
}
export const purchasegetdata =  async (params) => {
  // let result = await getSo(params)
  let res = await getSo(params);
  // result.saleOrderItemList = await getSoItem(params)
  let result = {
    data: res.data.records.length > 0 ? res.data.records[0] : [],
    code: res.code,
    message: res.message,
  };
  result.data.saleOrderItemList = await getSoItem(params);
  if(result.data.invoiceStatus !=='2') result.data = null
  return result;  
};

/**
 * 新增预留管理信息
 */
export const purchasechildadd = (data) => {
  return http({
    method: "post",
    headers: { "X-Custom-Header": "Loading" },
    url: process.env.VUE_APP_INVENTORY_URL+ `reserve/v1/reserves`,
    data
  });
};

/**
 * 外侧提交预留新增采购计划管理信息
 */
export const purchasechildoutadd = (data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_INVENTORY_URL+ `reserve/v1/reserves/auditstatus`,
    data
  });
};

/**
 * 外侧整单解除管理信息
 */
export const purchasegetalldel = (data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_INVENTORY_URL+ `reserve/v1/taskplans/updateplantypes`,
    data
  });
};

/**
 * 子级整单解除管理信息
 */
export const purchasegetallchilddel = (data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_INVENTORY_URL+ `reserve/v1/taskplans/oneplantype`,
    data
  });
};

/**
 * 修改预留管理信息
 */
export const purchasechildupd = (id,data) => {
  return http({
    method: "put",
    url: process.env.VUE_APP_INVENTORY_URL+ `reserve/v1/reserves/${id}`,
    data
  });
};

/**
 * 根据id查询预留管理信息
 */
export const purchasegetview = (id) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_INVENTORY_URL + `reserve/v1/reserves/${id}`,
  });
};

/**
 * 删除预留管理信息
 */
export const purchasegetdel = (id) => {
  return http({
    method: "delete",
    url: process.env.VUE_APP_INVENTORY_URL + `reserve/v1/reserves/${id}`,
  });
};

/**
   * 请求类型调取
   */
export const purchaserequesttype = () => {
  let res ={}
  res.data=[
    {
        "id": 623,
        "rev": 1,
        "createTime": "2021-01-13T03:26:59.147+0000",
        "updateTime": "2021-01-13T03:26:59.147+0000",
        "dictIndex": "purchaseType",
        "dictItemValue": "B099999",
        "dictItemName": "世家宝-样本采购",
        "dictItemOrder": 1,
        "remark": null,
        "dictItemLanguage": "CH"
    },
    {
        "id": 147,
        "rev": 1,
        "createTime": "2020-03-18T03:26:37.515+0000",
        "updateTime": "2020-03-18T03:26:37.515+0000",
        "dictIndex": "purchaseType",
        "dictItemValue": "B010201",
        "dictItemName": "以销定采-职业装",
        "dictItemOrder": 2,
        "remark": "业务类型",
        "dictItemLanguage": "CH"
    },
    {
        "id": 612,
        "rev": 1,
        "createTime": "2020-10-26T04:00:39.133+0000",
        "updateTime": "2020-10-26T04:00:39.133+0000",
        "dictIndex": "purchaseType",
        "dictItemValue": "B010206",
        "dictItemName": "以销定采-职业装-面料生产",
        "dictItemOrder": 3,
        "remark": null,
        "dictItemLanguage": "CH"
    },
    {
        "id": 149,
        "rev": 1,
        "createTime": "2020-03-18T03:26:37.515+0000",
        "updateTime": "2020-03-18T03:26:37.515+0000",
        "dictIndex": "purchaseType",
        "dictItemValue": "B010401",
        "dictItemName": "以销定采-订货大样",
        "dictItemOrder": 4,
        "remark": "业务类型",
        "dictItemLanguage": "CH"
    },
    {
        "id": 140,
        "rev": 1,
        "createTime": "2020-10-19T03:42:17.000+0000",
        "updateTime": "2020-10-19T03:42:20.000+0000",
        "dictIndex": "purchaseType",
        "dictItemValue": "B010501",
        "dictItemName": "以销定采-订货大货",
        "dictItemOrder": 5,
        "remark": null,
        "dictItemLanguage": "CH"
    },
    {
        "id": 148,
        "rev": 1,
        "createTime": "2020-03-18T03:26:37.515+0000",
        "updateTime": "2020-03-18T03:26:37.515+0000",
        "dictIndex": "purchaseType",
        "dictItemValue": "B010301",
        "dictItemName": "以销定采-零剪",
        "dictItemOrder": 6,
        "remark": "业务类型",
        "dictItemLanguage": "CH"
    },
    {
        "id": 171,
        "rev": 1,
        "createTime": "2020-05-31T23:43:34.614+0000",
        "updateTime": "2020-05-31T23:43:34.614+0000",
        "dictIndex": "purchaseType",
        "dictItemValue": "B010202",
        "dictItemName": "以销定采-零剪-叫料",
        "dictItemOrder": 7,
        "remark": null,
        "dictItemLanguage": "CH"
    },
    {
        "id": 611,
        "rev": 1,
        "createTime": "2020-10-26T03:59:33.314+0000",
        "updateTime": "2020-10-26T03:59:33.314+0000",
        "dictIndex": "purchaseType",
        "dictItemValue": "B010306",
        "dictItemName": "以销定采-零剪-面料生产",
        "dictItemOrder": 8,
        "remark": null,
        "dictItemLanguage": "CH"
    },
    {
        "id": 184,
        "rev": 1,
        "createTime": "2020-06-22T22:03:47.089+0000",
        "updateTime": "2020-06-22T22:03:47.089+0000",
        "dictIndex": "purchaseType",
        "dictItemValue": "B20305",
        "dictItemName": "备货采购-新首-职业装",
        "dictItemOrder": 9,
        "remark": null,
        "dictItemLanguage": "CH"
    },
    {
        "id": 185,
        "rev": 1,
        "createTime": "2020-06-22T22:04:27.451+0000",
        "updateTime": "2020-06-22T22:04:27.451+0000",
        "dictIndex": "purchaseType",
        "dictItemValue": "B20306",
        "dictItemName": "备货采购-新首补单-职业装",
        "dictItemOrder": 10,
        "remark": null,
        "dictItemLanguage": "CH"
    },
    {
        "id": 187,
        "rev": 1,
        "createTime": "2020-06-22T22:05:44.397+0000",
        "updateTime": "2020-06-22T22:05:44.397+0000",
        "dictIndex": "purchaseType",
        "dictItemValue": "B20308",
        "dictItemName": "备货采购-新首补单-零剪",
        "dictItemOrder": 11,
        "remark": null,
        "dictItemLanguage": "CH"
    },
    {
        "id": 186,
        "rev": 1,
        "createTime": "2020-06-22T22:05:05.803+0000",
        "updateTime": "2020-06-22T22:05:05.803+0000",
        "dictIndex": "purchaseType",
        "dictItemValue": "B20307",
        "dictItemName": "备货采购-新首-零剪",
        "dictItemOrder": 12,
        "remark": null,
        "dictItemLanguage": "CH"
    },
    {
        "id": 151,
        "rev": 1,
        "createTime": "2020-03-18T03:26:37.515+0000",
        "updateTime": "2020-03-18T03:26:37.515+0000",
        "dictIndex": "purchaseType",
        "dictItemValue": "B020302",
        "dictItemName": "备货采购-职业装",
        "dictItemOrder": 13,
        "remark": "业务类型",
        "dictItemLanguage": "CH"
    },
    {
        "id": 153,
        "rev": 1,
        "createTime": "2020-03-18T03:26:37.515+0000",
        "updateTime": "2020-03-18T03:26:37.515+0000",
        "dictIndex": "purchaseType",
        "dictItemValue": "B020203",
        "dictItemName": "备货采购-职业装-坯布/纱线",
        "dictItemOrder": 14,
        "remark": "业务类型",
        "dictItemLanguage": "CH"
    },
    {
        "id": 610,
        "rev": 1,
        "createTime": "2020-10-26T03:59:08.833+0000",
        "updateTime": "2020-10-26T03:59:08.833+0000",
        "dictIndex": "purchaseType",
        "dictItemValue": "B020206",
        "dictItemName": "备货采购-职业装-面料生产",
        "dictItemOrder": 15,
        "remark": null,
        "dictItemLanguage": "CH"
    },
    {
        "id": 150,
        "rev": 1,
        "createTime": "2020-03-18T03:26:37.515+0000",
        "updateTime": "2020-03-18T03:26:37.515+0000",
        "dictIndex": "purchaseType",
        "dictItemValue": "B020301",
        "dictItemName": "备货采购-零剪",
        "dictItemOrder": 16,
        "remark": "业务类型",
        "dictItemLanguage": "CH"
    },
    {
        "id": 608,
        "rev": 1,
        "createTime": "2020-10-23T02:31:45.536+0000",
        "updateTime": "2020-10-23T02:31:45.536+0000",
        "dictIndex": "purchaseType",
        "dictItemValue": "B020304",
        "dictItemName": "备货采购-零剪-世家宝",
        "dictItemOrder": 17,
        "remark": null,
        "dictItemLanguage": "CH"
    },
    {
        "id": 154,
        "rev": 1,
        "createTime": "2020-03-18T03:26:37.515+0000",
        "updateTime": "2020-03-18T03:26:37.515+0000",
        "dictIndex": "purchaseType",
        "dictItemValue": "B020303",
        "dictItemName": "备货采购-零剪-坯布/纱线",
        "dictItemOrder": 18,
        "remark": "业务类型",
        "dictItemLanguage": "CH"
    },
    {
        "id": 609,
        "rev": 1,
        "createTime": "2020-10-26T03:58:45.136+0000",
        "updateTime": "2020-10-26T03:58:45.136+0000",
        "dictIndex": "purchaseType",
        "dictItemValue": "B020306",
        "dictItemName": "备货采购-零剪-面料生产",
        "dictItemOrder": 19,
        "remark": null,
        "dictItemLanguage": "CH"
    },
    {
        "id": 637,
        "rev": 1,
        "createTime": "2021-02-01T10:42:11.309+0000",
        "updateTime": "2021-02-01T10:42:11.309+0000",
        "dictIndex": "purchaseType",
        "dictItemValue": "B020309",
        "dictItemName": "开发大样-零剪",
        "dictItemOrder": 20,
        "remark": null,
        "dictItemLanguage": "CH"
    },
    {
        "id": 644,
        "rev": 1,
        "createTime": "2021-10-29T06:57:46.888+0000",
        "updateTime": "2021-10-29T06:57:46.888+0000",
        "dictIndex": "purchaseType",
        "dictItemValue": "B020310",
        "dictItemName": "开发大样-职业装",
        "dictItemOrder": 21,
        "remark": null,
        "dictItemLanguage": "CH"
    },
    {
        "id": 197,
        "rev": 1,
        "createTime": "2020-10-13T02:28:40.000+0000",
        "updateTime": "2020-10-13T02:28:47.000+0000",
        "dictIndex": "purchaseType",
        "dictItemValue": "B020305",
        "dictItemName": "日常备货-集团",
        "dictItemOrder": 22,
        "remark": "业务类型",
        "dictItemLanguage": "CH"
    },
    {
        "id": 155,
        "rev": 1,
        "createTime": "2020-03-18T03:26:37.515+0000",
        "updateTime": "2020-03-18T03:26:37.515+0000",
        "dictIndex": "purchaseType",
        "dictItemValue": "B07",
        "dictItemName": "现货采购-原材料",
        "dictItemOrder": 23,
        "remark": "业务类型",
        "dictItemLanguage": "CH"
    },
    {
        "id": 201,
        "rev": 1,
        "createTime": "2020-08-18T04:29:52.942+0000",
        "updateTime": "2020-08-18T04:29:52.942+0000",
        "dictIndex": "purchaseType",
        "dictItemValue": "B9901",
        "dictItemName": "采购申请差异单",
        "dictItemOrder": 24,
        "remark": null,
        "dictItemLanguage": "CH"
    }
]
  res.code = '0'
// return http({
//     method: "get",
//     url: process.env.VUE_APP_INFRASTRUCTURE_URL+ `/infrastructure/v1/dicts?dictIndex=purchaseType`
// });
return res 
};

/**
   * 库位类型调取
   */
export const subinventoryquesttype=(virtualInventoryId) => {
return http({
    method: "get",
    url: process.env.VUE_APP_VIRTUALINVENTORY_URL+ `virtualinventory/v1/virtualinventorys/queryWmsSubInventory/${virtualInventoryId}`
});
};

/**
 * 获取在库可用库存
 */
export const getInventory = (params) => {
  let data = {
    orderBy: [],
    query:
      [{ fieldName: "warehouseNo", opr: "eq", value: params.inventory },
       { fieldName: "materialNo", opr: "eq", value: params.materialCode.toString() },
       { fieldName: "storageNo", opr: "eq", value: params.subInventory },],
    subTotal:
      [{ subTotalBy: "availQuantity", subTotalOpr: "SUM" }],
    groupBy: [],
  }

 let result=  http({
    method: "post",
    url: process.env.VUE_APP_INVENTORY_URL + `materialInventoryView/v2/index?currentPage=1&pageSize=9999`,
    data
 });
 result._then = result.then
 result.then = (cb) => {
   result._then((res) => {
     cb(res)
   })
 }
 return result
};
export const purchasegetInventory = async (data) => {
  // return http({
  //   method: "post",
  //   url: process.env.VUE_APP_INVENTORY_URL+ `recordinventory/v1/transactiondetails/availablestock`,
  //   data
  // });
  let res = await getInventory(data)
  let result = {
    data: res.data.records.length > 0 ? res.data.records[0].availQuantity : '',
    code: res.code,
    message: res.message,
  };
  return result
};

/**
   * 审批流程调取
   */
export const getapprovalOpinions=(flowUuid) => {
return http({
    method: "get",
    url: process.env.VUE_APP_INFRASTRUCTURE_URL+ `/bpm/v1/approvalOpinions?flowUuid=${flowUuid}`
});
};

/**
   *根据产品编号查询物料编号
   */
export const getmaterialCode=(productCode) => {
return http({
    method: "get",
    url: process.env.VUE_APP_PRODUCT_URL+ `productmaterial/v1/productmaterial/getMaterialCodeByProductCode?productCode=${productCode}`
});
};

/**
   *根据物料编号查询计量单位
   */
  export const getmaterialCodeUnit=(materialCode) => {
    return http({
        method: "get",
        url: process.env.VUE_APP_MATERIAL_URL+ `material/v1/materials/purchasemeasurementunit?materialCode=${materialCode}`
    });
  };

/**
 * 根据MO拉取销售工具生产单
 */
export const pullToolProductionList = (productionOrderCode) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_SCM_URL + `scm/SalesToolOrder/v1/queryByProductionOrderCode?productionOrderCode=${productionOrderCode}`
  });
};

/**
 * 根据MO拉取面料生产单
 */
export const pullClothProductionList = (productOrderCode) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_SCM_URL + `scm/productOrder/v1/queryByProductOrderCode?productOrderCode=${productOrderCode}`
  });
};

/**
 * 根据物料，仓位，库位，查询数据（给scm预留单用的）
 */
export const fetchReserveMessage = (data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_INVENTORY_URL + `inventory/v1/searchMessageToScmReserve`,
    data
  });
};

