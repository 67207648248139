import http from "../http";
import axios from "axios";

/**
 * 生产管理——面料生产
 * 分页查询成品生产申请项
 */
export const fabricProduceList = (currentPage, pageSize, data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `scm/v1/fetchproductorderRequestAndItems/search?currentPage=${currentPage}&pageSize=${pageSize}`,
    data
  });
};

/**
 * 分页查询面料生产订单申请单以及订单子项-草稿页面
 */
export const fabricProduceDraftList = (currentPage, pageSize, data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `scm/v1/fetchproductorderRequestAndItems/draft?currentPage=${currentPage}&pageSize=${pageSize}`,
    data
  });
};

/**
 * 删除成品生产申请单和申请项
 */
export const deleteFabricProduce = (id) => {
  return http({
    method: "delete",
    url: process.env.VUE_APP_SCM_URL + `scm/v1/salestoolorderrequestsbyid/${id}`
  });
};
/**
 * 删除发货订单
 */
export const deleteFhProduce = (id) => {
  return http({
    method: "delete",
    url: process.env.VUE_APP_SCM_URL + `deliverOrder/delMoDeliverOrderOutItemByid/${id}`
  });
};
/**
 * 关闭生产订单
 */
export const closeFabricProduces = (id,data) => {
  return http({
    method: "post",
    url:process.env.VUE_APP_SCM_URL +`scm/salesToolOrderRequest/v1/updateById`,
    data
  });
};

/**
 * 关闭成品生产申请单和申请项
 */
export const closeFabricProduce = (data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `scm/salesToolOrderRequest/v1/updateById`,
    data
  });
};

/**
 * 修改面料生产订单申请单
 */
export const editProduceList = (data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `scm/v1/salestoolorderrequests/submitApproval`,
    data
  });
};
/**
 * 发货单提交
 */
export const editProduceFhList = (id) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_SCM_URL + `deliverOrder/updateMoDeliverOrderOutItemByid/${id}`,
    
  });
};

// export const editProduceList = (data) => {
//   return http({
//     method: "post",
//     url: process.env.VUE_APP_SCM_URL + `scm/v1/salestoolorderrequestupdate`,
//     data
//   });
// };

/**
 * 根据id查询成品生产申请单
 */
export const searchFabricProduce = (id,status) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_SCM_URL +  (`${status}`=="fahuodan"?`deliverOrder/getMoDeliverOrderOutItemById/${id}`:`scm/v1/salestoolorderrequestanditems/${id}`) 
   
  });
};
export const searchFabricProducerk = (id) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_SCM_URL + `deliverOrder/inDetail/selectByInDetailId/${id}`
   
  });
};

export const searchFabricProduceFh = (id) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_SCM_URL + `deliverOrder/getMoDeliverOrderOutItemById/${id}`
    
   
  });
};
/**
 * 删除面料生产订单申请项以及所有原材料集合
 */
export const deleteProduceRequest = (sampleUuid) => {
  return http({
    method: "delete",
    url: process.env.VUE_APP_SCM_URL + `scm/v1/productorderrequestitemsdeletebysampleuuid/${sampleUuid}`
  });
};

/**
 * 删除成品生产申请项
 */
export const deleteProduceRequestItem = (id) => {
  return http({
    method: "delete",
    url: process.env.VUE_APP_SCM_URL + `scm/v1/productorderrequestitemsdelete/${id}`
  });
};

/**
 * 新增面料生产订单申请单及子项
 */
export const submitProduceList = (data) => {
  return http({
    method: "post",
    headers: { "X-Custom-Header": "Loading" },
    url: process.env.VUE_APP_SCM_URL + `scm/v1/salestoolorderrequests`,
    data
  });
};
/**
 * 新增面料生产订单申请单及子项
 */
export const submitProduceEducitionList = (data) => {
  return http({
    method: "post",
    headers: { "X-Custom-Header": "Loading" },
    url:process.env.VUE_APP_SCM_URL +`deliverOrder/MoDeliverOrder/addOrUpdate`,
    data
  });
};
/**
 * 确认面料生产订单申请单及子项
 */
export const confirmProduceList = (id) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_SCM_URL + `scm/v1/saleOrderTool/confirm/${id}`
  });
};
// export const confirmProduceList = (id, stStatus) => {
//   return http({
//     method: "get",
//     url: process.env.VUE_APP_SCM_URL + `scm/v1/saleOrderTool/confirm?id=${id}&stStatus=${stStatus}`
//   });
// };

/**
 * 仓位类型调取
 */
export const inventoryTypeList = () => {
  return http({
    method: "get",
    url: process.env.VUE_APP_INFRASTRUCTURE_URL + `infrastructure/v1/constants/dicts?dictIndex=VirtualInventory`
  });
};

/**
 * 库位类型调取
 */
export const subInventoryTypeList = (virtualInventoryId) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_VIRTUALINVENTORY_URL + `virtualinventory/v1/virtualinventorys/queryWmsSubInventory/${virtualInventoryId}`
  });
};

/**
 * 生产管理——销售工具生产
 * 分页查询销售工具生产订单申请以及申请项-非草稿页面
 */
export const toolProduceList = (currentPage, pageSize, data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `scm/v1/fetchsalestoolorderrequestAndItems/search?currentPage=${currentPage}&pageSize=${pageSize}`,
    data
  });
};
/**
 * 生产管理——面料转样本画册
 * 分页查询销售工具生产订单申请以及申请项-非草稿页面
 */
export const toolProductionExecutionList = (currentPage, pageSize, data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `scm/v1/fetchsalestoolorderMoDeliverOrderDetail?currentPage=${currentPage}&pageSize=${pageSize}`,
    data
  });
};
/**
 * 分页查询销售工具生产订单申请以及申请项-草稿页面
 */
export const toolProduceDraftList = (currentPage, pageSize, data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `scm/v1/fetchsalestoolorderrequestAndItems/draft?currentPage=${currentPage}&pageSize=${pageSize}`,
    data
  });
};

/**
 * 物料分类
 */
export const materialClassList = () => {
  return http({
    method: "get",
    url: process.env.VUE_APP_INFRASTRUCTURE_URL + `/infrastructure/v1/constants/dicts?dictIndex=MaterialClass`
  });
};

/**
 * 销售季
 */
export const salesSeasonList = () => {
    let res = {}
    res.data = [
      { "name": "S14", "index": "3" },
      { "name": "S15", "index": "4" },
      { "name": "S16", "index": "5" },
      { "name": "S17", "index": "6" },
      { "name": "S18", "index": "7" },
      { "name": "S19", "index": "1" },
      { "name": "S20", "index": "2" },
      { "name": "S21", "index": "18" },
      { "name": "S22", "index": "19" },
      { "name": "S23", "index": "21" },
      { "name": "S24", "index": "23" },
      { "name": "W10/11", "index": "8" },
      { "name": "W13/14", "index": "9" },
      { "name": "W14/15", "index": "10" },
      { "name": "W15/16", "index": "11" },
      { "name": "W16/17", "index": "12" },
      { "name": "W17/18", "index": "13" },
      { "name": "W18/19", "index": "14" },
      { "name": "W19/20", "index": "16" },
      { "name": "W20/21", "index": "15" },
      { "name": "W21/22", "index": "17" },
      { "name": "W22/23", "index": "20" },
      { "name": "W23/24", "index": "22" },
      { "name": "W24/25", "index": "24" }
    ]
    res.code = '0'
  // return http({
  //   method: "get",
  //   url: process.env.VUE_APP_INFRASTRUCTURE_URL + `/infrastructure/v1/constants/dicts?dictIndex=SaleSeasou`
  // });
  return res
};

/**
 * 常量查询数据字典
 */
export const constDictList = (dictIndex) => {
  if (dictIndex == 'VirtualInventory') {
    console.log('constDictList,VirtualInventory');
    let res ={}
    res.data = [
      {"name": "集团仓","index": "A0"},
      {"name": "零剪仓","index": "B0"},
      {"name": "职业装仓","index": "C0"},
      {"name": "订货仓","index": "D0" }
    ]
    res.code = '0'
    return res
  }
  if (dictIndex == 'Currency') {
    console.log('constDictList,Currency');
    let res ={}
    res.data = [
      {"name": "人民币","index": "1"},
      {"name": "美元","index": "2"},
      {"name": "欧元","index": "3"},
      {"name": "英镑","index": "4"},
      {"name": "日元","index": "5"},
      {"name": "韩元","index": "6"},
      {"name": "港币","index": "7"}
  ]
    res.code = '0'
    return res    
  }
  if (dictIndex == 'CombinedMaterialClass') {
    console.log('constDictList,CombinedMaterialClass');
    let res ={}
    res.data = [
      {"name": "样本","index": "1"},
      {"name": "画册","index": "2"}
    ]
    res.code = '0'
    return res    
  }  
  if (dictIndex == 'TransferType') {
    console.log('constDictList,TransferType');
    let res ={}
    res.data =  [
      {"name": "客订-工厂调拨","index": "A001"},
      {"name": "备货-工厂调拨","index": "A002"}
  ]
    res.code = '0'
    return res    
  }  
  if (dictIndex == 'SaleSeasou') {
    let res = {}
    res.data = [
      { "name": "S14", "index": "3" },
      { "name": "S15", "index": "4" },
      { "name": "S16", "index": "5" },
      { "name": "S17", "index": "6" },
      { "name": "S18", "index": "7" },
      { "name": "S19", "index": "1" },
      { "name": "S20", "index": "2" },
      { "name": "S21", "index": "18" },
      { "name": "S22", "index": "19" },
      { "name": "S23", "index": "21" },
      { "name": "S24", "index": "23" },
      { "name": "W10/11", "index": "8" },
      { "name": "W13/14", "index": "9" },
      { "name": "W14/15", "index": "10" },
      { "name": "W15/16", "index": "11" },
      { "name": "W16/17", "index": "12" },
      { "name": "W17/18", "index": "13" },
      { "name": "W18/19", "index": "14" },
      { "name": "W19/20", "index": "16" },
      { "name": "W20/21", "index": "15" },
      { "name": "W21/22", "index": "17" },
      { "name": "W22/23", "index": "20" },
      { "name": "W23/24", "index": "22" },
      { "name": "W24/25", "index": "24" }
    ]
    res.code = '0'
    return res
  }
  if (dictIndex == 'FinishedProductProduction') {
    let res = {}
    res.data =  [
      {
          "name": "POLO衫",
          "index": "1"
      },
      {
          "name": "毛衣",
          "index": "2"
      },
      {
          "name": "转换",
          "index": "3"
      },
      {
          "name": "非样本画册转换",
          "index": "4"
      },
      {
          "name": "样衣",
          "index": "8"
      }
  ]
    res.code = '0'
    return res
  }
  
  // FinishedProductProduction
  // return http({
  //   url: process.env.VUE_APP_INFRASTRUCTURE_URL + `/infrastructure/v1/constants/dicts?dictIndex=${dictIndex}`,
  // });
};

/**
 * 根据物料编号查询计量单位
 */
export const getmaterialCodeUnit=(materialCode) => {
  return http({
      method: "get",
      url: process.env.VUE_APP_MATERIAL_URL + `material/v1/materials/purchasemeasurementunit?materialCode=${materialCode}`
  });
};

/**
 * 根据生产物料编号查询原材料产品编号、原材料物料编号
 */
export const getOriginCodeByMC = (materialCode) => {
  return http({
      method: "get",
      url: process.env.VUE_APP_MATERIAL_URL + `combinedmessage/v1/combinedmaterialmessages/queryByMaterialCode?materialCode=${materialCode}`
  });
};
/**
 * 根据生产物料编号查询原材料产品编号、原材料物料编号
 * 查物料组成信息，只返回面料
 */
export const getOriginCodeByMCV2 = (materialCode) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_MATERIAL_URL + `combinedmessage/v1/combinedmaterialmessages/queryFabricMaterialByMaterialCode?materialCode=${materialCode}`
  });
};
// export const getOriginCodeByMC = (materialCode) => {
//   return http({
//       method: "get",
//       url: process.env.VUE_APP_SCM_URL + `deliverOrder/getmaterialmessages?materialCode=${materialCode}`
//   });
// };

/**
 * 查询生产订单下所有已确认发货数
 */
export const getShippedQuantity = (productionOrderCode) => {
  return http({
      method: "get",
      url: process.env.VUE_APP_SCM_URL + `deliverOrder/inDetail/selectConfirmNum?productionOrderCode=${productionOrderCode}`
  });
};