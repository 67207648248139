import http from "../http";
import axios from "axios";

/**
 * 分页查询采购计划管理查询
 */
export const transferOrderList = (currentPage, pageSize, data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `allocation/v1/allocation/search?currentPage=${currentPage}&pageSize=${pageSize}`,
    data
  });
};

/**
 * 删除子项展示table列表
 */
export const deleteTransferOrder = (id) => {
  return http({
    method: "delete",
    url: process.env.VUE_APP_SCM_URL + `allocation/v1/allocation/${id}`,
  });
};

/**
 * 关闭该条订单
 */
export const closeTransferOrder = (factoryAllocationCode) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_SCM_URL + `allocation/v1/closeAllocation?factoryAllocationCode=${factoryAllocationCode}`,
  });
};


/**
 * 外侧提交审批
 */
export const submitTransferOrder = (id) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_SCM_URL + `allocation/v1/submitallocationstobpm?id=${id}`,
  });
};

/**
 * 外侧撤销调拨单
 */
export const revokeTransferOrder = (factoryAllocationCode) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_SCM_URL + `allocation/v1/cancelAllocation?factoryAllocationCode=${factoryAllocationCode}`,
  });
};

/**
 * 编辑查看列表
 */
export const searchTransferOrder = (id,status) => {
 
  return http({
    method: "get",
   // url: process.env.VUE_APP_SCM_URL + `allocation/v1/allocationByThreeData/${id}`
     url: process.env.VUE_APP_SCM_URL +(`${status}`=="fission"?`allocation/v1/queryNoAllocation/${id}`:`allocation/v1/allocationByThreeData/${id}`) 
  });
};

/**
 * 根据物料编号查询确认书列表
 */
export const getConfirmNumbersByMC = (materialCode, inventory) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_SCM_URL + `allocation/v1/confirmingdodes?materialCode=${materialCode}&inventory=${inventory}`
  });
};

/**
 * 根据确认书号查询调拨数量
 */
export const getAllocationQuantityByCN = (materialCode, confirmNumber, packSpecification, inventory) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_SCM_URL + `cargoreadylist/v1/queryAllocationQuantity?materialCode=${materialCode}&confirmNumber=${encodeURIComponent(confirmNumber)}&packSpecification=${packSpecification}&inventory=${inventory}`
  });
};

/**
 * 新增采购计划拉取客订数据管理信息
 */
// export const pullCustomerOrder = (data) => {
//   return http({
//     method: "post",
//     url: process.env.VUE_APP_OMS_URL + `/saleorder/oms/saleorders/saleorderanditem`,
//     data
//   });
// };
const CommonUnit = {individual :'个',Box:'箱',Kg:'公斤',onePieces:'件',Cover:'套',Piece:'条',Book:'本',Meter:'米',Package:'包'}
export const getSoItem = (params) => {
  let data = {
    orderBy: [],
    query: [
      { fieldName: "soNo", opr: "eq", value: params.saleOrderId },
      { fieldName: "objectNo", opr: "eq", value: params.saleOrderId },
      { fieldName: "invoiceNo", opr: "eq", value: params.saleOrderId },
    ],
    subTotal: [],
    groupBy: []
  }
  let result = http({
    method: "post",
    url: process.env.VUE_APP_OMS_URL+ `soItem/v2/index?currentPage=1&pageSize=9999`,
    data
  })
  result._then = result.then
  result.then = (cb) => {
    result._then((res) => {
      let r = res.data.records
      r = r.map(item => {
        item.productCode = item.productNo // 产品编号
        item.materialCode = item.materialNo // 物料编号
        item.purchaseMeasurementUnit = CommonUnit[item.unit] // 单位字段
        item.saleOrderId = item.soNo;
        return item
      })
      cb(r)
    })
  }
  return result
 }
 export const getSo = (params) => {
  let data = { orderBy: [], query: [{ fieldName: "invoiceNo", opr: "eq", value: params.saleOrderId }], subTotal: [], groupBy: [] }
  let result = http({
    method: "post",
    url: process.env.VUE_APP_OMS_URL+ `so/v2/index?currentPage=1&pageSize=20`,
    data
  })
  result._then = result.then
  result.then = (cb) => {
    result._then((res) => {
      let r = res.data.records
      r = r.map( item => {
        item.customerName = item.businessCustomerName;
        item.customerId = item.businessCustomerNo;
        item.saleOrderType = item.soType;
        item.saleOrderId = item.invoiceNo;
        return item
      })[0]
      cb(res)
    })
  }
  return result
 }
export const pullCustomerOrder =  async (params) => {
  let res = await getSo(params)
  let result = {
    data: res.data.records.length > 0 ? res.data.records[0] : [],
    code: res.code,
    message: res.message,
  };
  result.data.saleOrderItemList = await getSoItem(params);
  return result
};
/**
 * 查询所有物料建议调拨数量
 */
export const pullCustomerOrderRequest = (data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `plan/v1/queryAllTransfer`,
    data
  });
};

/**
 * 根据入库仓位查询建议调拨数量
 */
export const getTPQuantityByInventory = (materialCode, inventory) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_SCM_URL + `allocation/v1/queryTransferQuantity?materialCode=${materialCode}&inventory=${inventory}`
  });
};

/**
 * 新增子项form列表
 */
export const updateTransferOrder = (data,status) => {
  return http({
    method: "post",
    headers: { "X-Custom-Header": "Loading" },
    //url: process.env.VUE_APP_SCM_URL + `allocation/v1/allocation`,
    url: process.env.VUE_APP_SCM_URL +(`${status}`=="fission"?`allocation/v1/saveAllocationFission`:`allocation/v1/allocation`) ,
    data
  });
};


/**
 * 新增Pr差异单form列表
 */
export const createDifferencePrOrder = (data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `allocation/v1/createPRCredit`,
    // url: process.env.VUE_APP_SCM_URL +(`${status}`=="fission"?`allocation/v1/createPRCredit`:`allocation/v1/createPRCredit`) ,
    data
  });
};

/**
 * 库位类型调取(仅限库内调拨)
 */
export const subInventoryRollsList = (virtualInventoryId) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_VIRTUALINVENTORY_URL + `virtualinventory/v1/virtualinventorys/queryWmsSubInventory/${virtualInventoryId}`
  });
};

/**
 * 获取在库可用库存--类型工厂调拨
 */
export const factoryAvailableInventory = (data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `allocation/v1/availableinventory`,
    data
  });
};

/**
 * 获取在库可用库存--类型库内调拨
 */
export const getInventory1 = (params) => {
  let data = {
    orderBy: [],
    query:
      [{ fieldName: "warehouseNo", opr: "eq", value: params.inventory },
       { fieldName: "materialNo", opr: "eq", value: params.materialCode },
       { fieldName: "storageNo", opr: "eq", value: params.subInventory },],
    subTotal:
      [{ subTotalBy: "availQuantity", subTotalOpr: "SUM" }],
    groupBy: [],
  }

 let result=  http({
    method: "post",
    url: process.env.VUE_APP_INVENTORY_URL + `materialInventoryView/v2/index?currentPage=1&pageSize=9999`,
    data
 });
 result._then = result.then
 result.then = (cb) => {
   result._then((res) => {
     cb(res)
   })
 }
 return result
};
export const cuneAvailableInventory = async (data) => {
  // return http({
  //   method: "post",
  //   url: process.env.VUE_APP_INVENTORY_URL + `recordinventory/v1/transactiondetails/availablestock`,
  //   data
  // });
  let res = await getInventory1(data)
  let result = {
    data: res.data.records.length > 0 ? res.data.records[0].availQuantity : '',
    code: res.code,
    message: res.message,
  };
  return result
};

/**
 * 查询建议调拨数量
 */
export const searchTransferPlanQuantity = (data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `plan/v1/queryTransfer`,
    data
  });
};
/**
 * 采购申请新增也调用：批量查询物料
 */
export const batchQuery = (data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_MATERIAL_URL + `material/v1/materials/querytMaterialAndFinishedProductFetch`,
    data
  });
};
/**
 * 物料可用库存
 */
export const availablestock = async (data) => {
  // return http({
  //   method: "post",
  //   url: process.env.VUE_APP_INVENTORY_URL + `recordinventory/v1/transactiondetails/availablestock`,
  //   data
  // });
  let res = await getInventory1(data)
  let result = {
    data: res.data.records.length > 0 ? res.data.records[0].availQuantity : '',
    code: res.code,
    message: res.message,
  };
  return result
};

/**
 * NEW
 * 物料可用库存（调出库可用库存）-库内调拨
 */
export const fetchAvailableInventory = async (data) => {
  // return http({
  //   method: "post",
  //   url: process.env.VUE_APP_INVENTORY_URL + `recordinventory/v1/transactiondetails/availablestock`,
  //   data
  // });
  let res = await getInventory1(data)
  let result = {
    data: res.data.records.length > 0 ? res.data.records[0].availQuantity : '',
    code: res.code,
    message: res.message,
  };
  return result
};

/**
 * NEW
 * 物料可用库存（调出库可用库存）-工厂调拨
 */
export const fetchFactoryAvailableInventory = (data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `allocation/v1/availableinventory`,
    data
  });
};

/**
 * 查询所有预留ID
 */
export const getMaterialInventoryBatchNo = (params) => {
  let data = {
    orderBy: [],
    query:
      [
       { fieldName: "warehouseNo", opr: "eq", value: params.inventory },
       { fieldName: "materialNo", opr: "eq", value: params.materialCode.toString() },
       { fieldName: "storageNo", opr: "eq", value: params.subInventory },
      ],
    subTotal:[],
    groupBy: [],
  }

 let result=  http({
    method: "post",
    url: process.env.VUE_APP_INVENTORY_URL + `materialInventoryView/v2/index?currentPage=1&pageSize=9992`,
    data
 });
  result._then = result.then
  result.then = (cb) => {
    result._then((res) => {
      cb(res)
    })
  }
 return result
};
export const getBatchNoList = async (data) => {
  // return http({
  //   method: "post",
  //   url: process.env.VUE_APP_SCM_URL + `inventory/v1/MaterialInventory/getBatchNoList`,
  //   data
  // });
  let res = await getMaterialInventoryBatchNo(data)
  let records =[]
  res.data.records.forEach(item => {
    records.push(item.batchNo)
  })
  let result = {
    data: res.data.records.length > 0 ? records : [],
    code: res.code,
    message: res.message,
  };
  return result
};
/**
 * 查询所有预留ID
 */
const VirtualInventory = {D0:'订货仓',C0:'职业装仓',B0:'零剪仓',A0:'集团仓',}
export const getMaterialInventory = (params) => {
  let data = {
    orderBy: [],
    query:
      [{ fieldName: "warehouseNo", opr: "eq", value: params.inventory },
       { fieldName: "materialNo", opr: "eq", value: params.materialCode.toString() },
       { fieldName: "storageNo", opr: "eq", value: params.subInventory },
       { fieldName: "batchNo", opr: "eq", value: params.batchNo },],
    subTotal:[],
    groupBy: [],
  }

 let result=  http({
    method: "post",
    url: process.env.VUE_APP_INVENTORY_URL + `materialInventoryView/v2/index?currentPage=1&pageSize=9991`,
    data
 });
  result._then = result.then
  result.then = (cb) => {
    result._then((res) => {
      let r = res.data.records
      r = r.map(item => {
           item.availQuantity =  item.availQuantity   
           item.batchNo =  item.batchNo               
           item.factoryBatchNumber =  item.factoryBatchNo  
           item.inventory =  item.warehouseNo           
           item.inventoryName =  VirtualInventory[item.warehouseNo]  
           item.materialCode =  item.materialNo              
           item.quantity =  item.quantity                    
           item.subInventory = item.subInventory               
          //  item.simpleCode =  item.                   //    姜超
          //  item.unit =  item.                         //    姜超
          //  item.productCode =  item.                  //    姜超
          //  item.bookCode =  item.                     //    姜超

        return item
      })
      cb(res)
    })
  }
 return result
};
export const searchDetail = async (data) => {
  // return http({
  //   method: "post",
  //   url: process.env.VUE_APP_SCM_URL + `inventory/v1/MaterialInventory/searchDetail`,
  //   data
  // });
  let res = await getMaterialInventory(data)
  let result = {
    data: res.data.records.length > 0 ? res.data.records : '',
    code: res.code,
    message: res.message,
  };
  return result
};
/* 
aBSOwnerId: "nanchunyu"
allowRoles: null
allowUsers: null
availQuantity: 59
batchNo: "FP1381-2-1"
batchStatus: "Available"
createTime: "2023-02-03T11:38:00"
createUser: null
deleteStatus: null
enName: null
factoryBatchNo: "FP1381-2-1"
id: 1654314
isActive: null
isSync: null
lockQuantity: 13
materialNo: "V1009.23/175"
materialSn: "MN106970"
name: null
objectNo: null
objectStatus: "Available"
objectType: null
ownerId: null
quantity: 72
refMasterDataNo: null
remarks: ""
rev: 1
status: null
storageNo: "02"
transactionTime: null
updateTime: "2023-02-03T11:38:00"
updateUser: null
uuid: "117528296587771720202302031138003311000033"
vatNo: "GH1381-2-1"
warehouseNo: "B0"


*/
/**
 * 根据确认书和物料编码查出货妥规格集合
 */
export const getPaidMeterByCN = (data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `allocation/v1/getAvailableinventoryAndSpecification`,
    data
  });
};

/**
 * 根据确认书和物料编码带出货妥记录的付款方式
 */
export const getPaymentMethodByCN = (data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `cargoreadylist/v1/getPaymentMethod`,
    data
  });
};

/**
 * 根据产品编号批量查询产品描述
 */
export const getDescByPC = (data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_PRODUCT_URL + `product/v1/queryDescribeByProductCode`,
    data
  });
};

/**
 * Excel批量导入数据
 */
export const getBatchImport = (data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `allocation/v1/importBatch`,
    data
  });
};

/**
 * Excel批量导入数据移库
 */
export const getBatchImportMove = (data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `allocation/v1/importBatchMove`,
    data
  });
};
export const batchQueryForRelocation = (data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `allocation/v1/importBatchMove`,
    data
  });
};

/**
 * 移库批量统计可用库存最大块数量
 */
export const getInventory = (params) => {
  let data = {
    orderBy: [],
    query: [
      { fieldName: "warehouseNo", opr: "eq", value: params.inventory },
      { fieldName: "materialNo", opr: "eq", value: params.materialCode },
      { fieldName: "storageNo", opr: "eq", value: params.subInventory },
    ],
    subTotal: [
      { subTotalBy: "availQuantity", subTotalOpr: "SUM"},
      { subTotalBy: "quantity", subTotalOpr: "MAX"}],
    groupBy: [],
  }
 let result=  http({
    method: "post",
    url: process.env.VUE_APP_INVENTORY_URL + `materialInventoryView/v2/index?currentPage=1&pageSize=9999`,
    data
 });
 result._then = result.then
 result.then = (cb) => {
   result._then((res) => {
     let r = res.data.records
     r = r.map(item => {
       item.avaliableQuantity = item.availQuantity // 可用总量
       item.maxBlockLength = item.quantity // 最大块
       item.materialCode= params.materialCode, // 物料编号
       item.uuid= params.uuid //
       return item
     })
     cb(res)
   })
 }
 return result
};

 export const getAvailablestockMaxBatch = (data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_INVENTORY_URL + `recordinventory/v1/transactiondetails/availablestockMaxBatch`,
    data
  });
};
export const inventoryMaxBlockLength = async (data) => {
  let result = {
    code: '',
    message: '',
    data :[]
  }
  for (var i = 0; i < data.length; i++) {
    console.log('data[i]', i ,data[i]);
    let res = await getInventory(data[i])
    result.code = res.code
    result.message = res.message
    // @ts-ignore
    result.data = [...result.data,res.data.records[0]]
  }  
  console.log('result',result);
  return result
  // return http({
  //   method: "post",
  //   url: process.env.VUE_APP_INVENTORY_URL + `recordinventory/v1/transactiondetails/availablestockMaxBatch`,
  //   data
  // });

};

/**
 * 库存服务（库存调整保存）
 */
 export const getStockAdjust = (actionType,data) => {
  return http({
    method: "post",
    headers: {
      'Content-Type':'application/json',
    },
    url: process.env.VUE_APP_INVENTORY_URL + `inventory/v1/stockAdjust?actionType=${actionType}`,
    data
  });
};

/**
 * 库存服务（移库入）
 */
 export const getStockMoveIn = (actionType,data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_INVENTORY_URL + `inventory/v1/stockMoveIn?actionType=${actionType}`,
    data
  });
};

/**
 * 库存服务（移库出&移库入）
 */
 export const getStockMoveOut = (actionType,data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_INVENTORY_URL + `inventory/v1/stockMoveOut?actionType=${actionType}`,
    data
  });
};
