import { generateKey } from '@/utils/index.js';
import {
  getMeasurementUnitByMC,
  existMaterialCode,
  subInventoryTypeList,
  wmsSubInventoryList,
  constDictList,
  dictList,
  supplierDictList,
  getMaterialCode,
  getBatchQuery
} from './publicApi.js';
import {
  getConfirmNumbersByMC,
  subInventoryRollsList,
  getBatchImport
} from './module/transferOrderApi.js';
import {
  purchasegetInventory
} from './module/decisionReservedApi.js';
import {
  getOriginCodeByMCV2
} from './module/toolProduceApi.js';

/**
 * 常量数据字典
 */
export async function constDictListCache(dictIndex) {
  const key = generateKey('constDictListCache', [dictIndex]);
  let data = Vue.prototype.expriesCache.get(key);
  if (!data) {
    let res = await constDictList(dictIndex);
    data = res;
    Vue.prototype.expriesCache.set(key, res, 172800);
  }
  return data;
}

/**
 * 查询数据字典
 */
export async function dictListCache(dictIndex) {
  if (dictIndex == 'purchaseType') {
    console.log(dictIndex,'dictIndex');
    let res ={}
    res.data =  [
      {
          "id": 623,
          "rev": 1,
          "createTime": "2021-01-13T03:26:59.147+0000",
          "updateTime": "2021-01-13T03:26:59.147+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B099999",
          "dictItemName": "世家宝-样本采购",
          "dictItemOrder": 1,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 147,
          "rev": 1,
          "createTime": "2020-03-18T03:26:37.515+0000",
          "updateTime": "2020-03-18T03:26:37.515+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B010201",
          "dictItemName": "以销定采-职业装",
          "dictItemOrder": 2,
          "remark": "业务类型",
          "dictItemLanguage": "CH"
      },
      {
          "id": 612,
          "rev": 1,
          "createTime": "2020-10-26T04:00:39.133+0000",
          "updateTime": "2020-10-26T04:00:39.133+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B010206",
          "dictItemName": "以销定采-职业装-面料生产",
          "dictItemOrder": 3,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 149,
          "rev": 1,
          "createTime": "2020-03-18T03:26:37.515+0000",
          "updateTime": "2020-03-18T03:26:37.515+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B010401",
          "dictItemName": "以销定采-订货大样",
          "dictItemOrder": 4,
          "remark": "业务类型",
          "dictItemLanguage": "CH"
      },
      {
          "id": 140,
          "rev": 1,
          "createTime": "2020-10-19T03:42:17.000+0000",
          "updateTime": "2020-10-19T03:42:20.000+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B010501",
          "dictItemName": "以销定采-订货大货",
          "dictItemOrder": 5,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 148,
          "rev": 1,
          "createTime": "2020-03-18T03:26:37.515+0000",
          "updateTime": "2020-03-18T03:26:37.515+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B010301",
          "dictItemName": "以销定采-零剪",
          "dictItemOrder": 6,
          "remark": "业务类型",
          "dictItemLanguage": "CH"
      },
      {
          "id": 171,
          "rev": 1,
          "createTime": "2020-05-31T23:43:34.614+0000",
          "updateTime": "2020-05-31T23:43:34.614+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B010202",
          "dictItemName": "以销定采-零剪-叫料",
          "dictItemOrder": 7,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 611,
          "rev": 1,
          "createTime": "2020-10-26T03:59:33.314+0000",
          "updateTime": "2020-10-26T03:59:33.314+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B010306",
          "dictItemName": "以销定采-零剪-面料生产",
          "dictItemOrder": 8,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 184,
          "rev": 1,
          "createTime": "2020-06-22T22:03:47.089+0000",
          "updateTime": "2020-06-22T22:03:47.089+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B20305",
          "dictItemName": "备货采购-新首-职业装",
          "dictItemOrder": 9,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 185,
          "rev": 1,
          "createTime": "2020-06-22T22:04:27.451+0000",
          "updateTime": "2020-06-22T22:04:27.451+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B20306",
          "dictItemName": "备货采购-新首补单-职业装",
          "dictItemOrder": 10,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 187,
          "rev": 1,
          "createTime": "2020-06-22T22:05:44.397+0000",
          "updateTime": "2020-06-22T22:05:44.397+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B20308",
          "dictItemName": "备货采购-新首补单-零剪",
          "dictItemOrder": 11,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 186,
          "rev": 1,
          "createTime": "2020-06-22T22:05:05.803+0000",
          "updateTime": "2020-06-22T22:05:05.803+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B20307",
          "dictItemName": "备货采购-新首-零剪",
          "dictItemOrder": 12,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 151,
          "rev": 1,
          "createTime": "2020-03-18T03:26:37.515+0000",
          "updateTime": "2020-03-18T03:26:37.515+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B020302",
          "dictItemName": "备货采购-职业装",
          "dictItemOrder": 13,
          "remark": "业务类型",
          "dictItemLanguage": "CH"
      },
      {
          "id": 153,
          "rev": 1,
          "createTime": "2020-03-18T03:26:37.515+0000",
          "updateTime": "2020-03-18T03:26:37.515+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B020203",
          "dictItemName": "备货采购-职业装-坯布/纱线",
          "dictItemOrder": 14,
          "remark": "业务类型",
          "dictItemLanguage": "CH"
      },
      {
          "id": 610,
          "rev": 1,
          "createTime": "2020-10-26T03:59:08.833+0000",
          "updateTime": "2020-10-26T03:59:08.833+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B020206",
          "dictItemName": "备货采购-职业装-面料生产",
          "dictItemOrder": 15,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 150,
          "rev": 1,
          "createTime": "2020-03-18T03:26:37.515+0000",
          "updateTime": "2020-03-18T03:26:37.515+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B020301",
          "dictItemName": "备货采购-零剪",
          "dictItemOrder": 16,
          "remark": "业务类型",
          "dictItemLanguage": "CH"
      },
      {
          "id": 608,
          "rev": 1,
          "createTime": "2020-10-23T02:31:45.536+0000",
          "updateTime": "2020-10-23T02:31:45.536+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B020304",
          "dictItemName": "备货采购-零剪-世家宝",
          "dictItemOrder": 17,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 154,
          "rev": 1,
          "createTime": "2020-03-18T03:26:37.515+0000",
          "updateTime": "2020-03-18T03:26:37.515+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B020303",
          "dictItemName": "备货采购-零剪-坯布/纱线",
          "dictItemOrder": 18,
          "remark": "业务类型",
          "dictItemLanguage": "CH"
      },
      {
          "id": 609,
          "rev": 1,
          "createTime": "2020-10-26T03:58:45.136+0000",
          "updateTime": "2020-10-26T03:58:45.136+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B020306",
          "dictItemName": "备货采购-零剪-面料生产",
          "dictItemOrder": 19,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 637,
          "rev": 1,
          "createTime": "2021-02-01T10:42:11.309+0000",
          "updateTime": "2021-02-01T10:42:11.309+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B020309",
          "dictItemName": "开发大样-零剪",
          "dictItemOrder": 20,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 644,
          "rev": 1,
          "createTime": "2021-10-29T06:57:46.888+0000",
          "updateTime": "2021-10-29T06:57:46.888+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B020310",
          "dictItemName": "开发大样-职业装",
          "dictItemOrder": 21,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 197,
          "rev": 1,
          "createTime": "2020-10-13T02:28:40.000+0000",
          "updateTime": "2020-10-13T02:28:47.000+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B020305",
          "dictItemName": "日常备货-集团",
          "dictItemOrder": 22,
          "remark": "业务类型",
          "dictItemLanguage": "CH"
      },
      {
          "id": 155,
          "rev": 1,
          "createTime": "2020-03-18T03:26:37.515+0000",
          "updateTime": "2020-03-18T03:26:37.515+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B07",
          "dictItemName": "现货采购-原材料",
          "dictItemOrder": 23,
          "remark": "业务类型",
          "dictItemLanguage": "CH"
      },
      {
          "id": 201,
          "rev": 1,
          "createTime": "2020-08-18T04:29:52.942+0000",
          "updateTime": "2020-08-18T04:29:52.942+0000",
          "dictIndex": "purchaseType",
          "dictItemValue": "B9901",
          "dictItemName": "采购申请差异单",
          "dictItemOrder": 24,
          "remark": null,
          "dictItemLanguage": "CH"
      }
  ]
    res.code = '0'
    console.log('res',res);
    return  res
  }
  if (dictIndex == 'ReservedType') {
    console.log(dictIndex,'ReservedType');
    let res ={}
    res.data =  [
      {
          "id": 126,
          "rev": 1,
          "createTime": "2020-02-27T14:41:01.000+0000",
          "updateTime": "2020-02-27T14:41:04.000+0000",
          "dictIndex": "ReservedType",
          "dictItemValue": "A001",
          "dictItemName": "客订-面料",
          "dictItemOrder": 1,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 127,
          "rev": 1,
          "createTime": "2020-02-27T15:16:13.000+0000",
          "updateTime": "2020-02-27T15:16:16.000+0000",
          "dictIndex": "ReservedType",
          "dictItemValue": "A002",
          "dictItemName": "客订-样本样布",
          "dictItemOrder": 2,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 128,
          "rev": 1,
          "createTime": "2020-02-27T15:17:56.000+0000",
          "updateTime": "2020-02-27T15:17:58.000+0000",
          "dictIndex": "ReservedType",
          "dictItemValue": "A003",
          "dictItemName": "客订-画册样布",
          "dictItemOrder": 3,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 129,
          "rev": 1,
          "createTime": "2020-02-27T15:19:07.000+0000",
          "updateTime": "2020-02-27T15:19:09.000+0000",
          "dictIndex": "ReservedType",
          "dictItemValue": "A004",
          "dictItemName": "客订-样衣样布",
          "dictItemOrder": 4,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 130,
          "rev": 1,
          "createTime": "2020-02-27T15:19:49.000+0000",
          "updateTime": "2020-02-27T15:19:52.000+0000",
          "dictIndex": "ReservedType",
          "dictItemValue": "A005",
          "dictItemName": "客订-其他样布",
          "dictItemOrder": 5,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 131,
          "rev": 1,
          "createTime": "2020-02-27T15:20:35.000+0000",
          "updateTime": "2020-02-27T15:20:40.000+0000",
          "dictIndex": "ReservedType",
          "dictItemValue": "B006",
          "dictItemName": "非客订-面料",
          "dictItemOrder": 6,
          "remark": "123123123",
          "dictItemLanguage": "CH"
      },
      {
          "id": 132,
          "rev": 1,
          "createTime": "2020-02-27T15:21:46.000+0000",
          "updateTime": "2020-02-27T15:21:48.000+0000",
          "dictIndex": "ReservedType",
          "dictItemValue": "B007",
          "dictItemName": "非客订-画册样布",
          "dictItemOrder": 7,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 133,
          "rev": 1,
          "createTime": "2020-02-27T15:22:33.000+0000",
          "updateTime": "2020-02-27T15:22:44.000+0000",
          "dictIndex": "ReservedType",
          "dictItemValue": "B008",
          "dictItemName": "非客订-样本样布",
          "dictItemOrder": 8,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 134,
          "rev": 1,
          "createTime": "2020-02-27T15:23:31.000+0000",
          "updateTime": "2020-02-27T15:23:33.000+0000",
          "dictIndex": "ReservedType",
          "dictItemValue": "B009",
          "dictItemName": "非客订-其他样布",
          "dictItemOrder": 9,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 135,
          "rev": 1,
          "createTime": "2020-02-27T15:24:37.000+0000",
          "updateTime": "2020-02-27T15:24:39.000+0000",
          "dictIndex": "ReservedType",
          "dictItemValue": "B010",
          "dictItemName": "非客订-样衣样布",
          "dictItemOrder": 10,
          "remark": null,
          "dictItemLanguage": "CH"
      },
      {
          "id": 136,
          "rev": 1,
          "createTime": "2020-02-27T15:25:08.000+0000",
          "updateTime": "2020-02-27T15:25:10.000+0000",
          "dictIndex": "ReservedType",
          "dictItemValue": "C011",
          "dictItemName": "其他",
          "dictItemOrder": 11,
          "remark": null,
          "dictItemLanguage": "CH"
      }
  ]
    res.code = '0'
    console.log('res',res);
    return  res
  }
  const key = generateKey('dictListCache', [dictIndex]);
  let data = Vue.prototype.expriesCache.get(key);
  if (!data) {
    let res = await dictList(dictIndex);
    data = res;
    Vue.prototype.expriesCache.set(key, res, 172800);
  }
  return data;
}

/**
 * 库位列表
 */
export async function subInventoryListCache(virtualInventoryId) {
  const key = generateKey('subInventoryListCache', [virtualInventoryId]);
  let data = Vue.prototype.expriesCache.get(key);
  if (!data) {
    let res = await subInventoryTypeList(virtualInventoryId);
    data = res;
    Vue.prototype.expriesCache.set(key, res, 172800);
  }
  return data;
}

/**
 * WMS虚拟库位列表
 */
export async function wmsSubInventoryListCache(virtualInventoryId) {
  const key = generateKey('wmsSubInventoryListCache', [virtualInventoryId]);
  let data = Vue.prototype.expriesCache.get(key);
  if (!data) {
    let res = await wmsSubInventoryList(virtualInventoryId);
    data = res;
    Vue.prototype.expriesCache.set(key, res, 172800);
  }
  return data;
}

/**
 * 供应商列表字典
 */
export async function supplierListCache() {
  const key = generateKey('supplierListCache', []);
  let data = Vue.prototype.expriesCache.get(key);
  if (!data) {
    let res = await supplierDictList();
    data = res;
    Vue.prototype.expriesCache.set(key, res, 172800);
  }
  return data;
}

/*
* 移库、计划预留-根据产品编号查询物料编号
*/
export async function getMaterialCodeCache(productCode) {
  // 生成key
  const key = generateKey('getMaterialCodeCache', [productCode]);
  // 获得数据
  let data = Vue.prototype.expriesCache.get(key);
  if (!data) {
    let res = await getMaterialCode(productCode);
    data = res;
    // 使用 10s 缓存，10s之后再次get就会 获取null 而从服务端继续请求
    Vue.prototype.expriesCache.set(key, res);
  }
  return data;
}

/**
 * 调拨-根据物料编号查询数据库是否存在此物料编号
 */
export async function existMaterialCodeCache(materialCode) {
  const key = generateKey('existMaterialCodeCache', [materialCode]);
  let data = Vue.prototype.expriesCache.get(key);
  if (!data) {
    let res = await existMaterialCode(materialCode);
    data = res;
    Vue.prototype.expriesCache.set(key, res, 86400);
  }
  return data;
}

/**
 * 根据物料编号查询计量单位
 */
export async function getMeasurementUnitCache(materialCode) {
  const key = generateKey('getMeasurementUnitCache', [materialCode]);
  let data = Vue.prototype.expriesCache.get(key);
  if (!data) {
    let res = await getMeasurementUnitByMC(materialCode);
    data = res;
    Vue.prototype.expriesCache.set(key, res, 172800);  // 2天缓存
  }
  return data;
}

/**
 * 预留 根据物料编号查询计量单位
 */
export async function purchasegetInventoryCache(obj) {
  const key = generateKey('purchasegetInventoryCache', [obj]);
  let data = Vue.prototype.expriesCache.get(key);
  if (!data) {
    let res = await purchasegetInventory(obj);
    data = res;
    Vue.prototype.expriesCache.set(key, res, 172800);  // 2天缓存
  }
  return data;
}

/**
 * 批量查询物料
 */
export async function getBatchQueryCache(batchParams) {
  let strA = batchParams.businessType ? batchParams.businessType.join(',') : '';
  let strB = batchParams.materialCodeList ? batchParams.materialCodeList.join(',') : '';
  const key = generateKey('getBatchQueryCache', [strA + strB]);
  let data = Vue.prototype.expriesCache.get(key);
  if (!data) {
    let res = await getBatchQuery(batchParams);
    data = res;
    Vue.prototype.expriesCache.set(key, res);
  }
  return data;
}

/**
 * 根据物料编号查询确认书列表
 */
export async function getConfirmNumbersCache(materialCode, inventory) {
  const key = generateKey('getConfirmNumbersCache', [materialCode, inventory]);
  let data = Vue.prototype.expriesCache.get(key);
  if (!data) {
    let res = await getConfirmNumbersByMC(materialCode, inventory);
    data = res;
    Vue.prototype.expriesCache.set(key, res);
  }
  return data;
}

/**
 * 生产管理销售工具-根据生产物料编号查询原材料产品编号、原材料物料编号
 */
export async function getSourceCodeCache(materialCode) {
  const key = generateKey('getSourceCodeCache', [materialCode]);
  let data = Vue.prototype.expriesCache.get(key);
  if (!data) {
    let res = await getOriginCodeByMCV2(materialCode);
    data = res;
    Vue.prototype.expriesCache.set(key, res, 86400);
  }
  return data;
}

/**
 * 库位列表库位类型调取（仅限库内调拨）
 */
export async function subInventoryRollsListCache(virtualInventoryId) {
  const key = generateKey('subInventoryRollsListCache', [virtualInventoryId]);
  let data = Vue.prototype.expriesCache.get(key);
  if (!data) {
    let res = await subInventoryRollsList(virtualInventoryId);
    data = res;
    Vue.prototype.expriesCache.set(key, res, 172800);
  }
  return data;
}
